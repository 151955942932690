'use client';

import { useRemoveItemFromWishlist } from '../../hooks';
import { type Trigger, type Item } from '../../model';

interface WishlistDrawerProps {
  item: Item;
  trigger: Trigger;
  isDisabled: boolean;
  ariaLabel: string;
  onClick?: () => void;
  onRemove?: () => void;
}

function RemoveFromWishlistButton(props: Readonly<WishlistDrawerProps>) {
  const {
    item,
    trigger: TriggerButton,
    isDisabled,
    ariaLabel,
    onClick,
    onRemove,
  } = props;

  const { remove } = useRemoveItemFromWishlist({ onSuccess: onRemove });

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    remove(item);
  };

  return (
    <TriggerButton
      aria-label={ariaLabel}
      isDisabled={isDisabled}
      onClick={handleClick}
      isInWishlist
    />
  );
}

export default RemoveFromWishlistButton;
