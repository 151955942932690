import { tv } from 'tailwind-variants';

export const styles = tv({
  base: `
    [&_.new-layout.container]:w-full
    [&_.new-layout.container]:max-w-full
    [&_.new-layout.container]:px-4

    [&_.new-layout_.flex.right]:flex-col
    [&_.new-layout_.flex.right]:md:flex-row-reverse
    [&_.new-layout_.flex]:my-4
    [&_.new-layout_.flex]:flex
    [&_.new-layout_.flex]:flex-col
    [&_.new-layout_.flex]:items-center
    [&_.new-layout_.flex]:gap-0
    [&_.new-layout_.flex]:md:flex-row
    [&_.new-layout_.flex]:md:gap-8

    [&_.new-layout_.text-center]:text-center
    [&_.new-layout_.text-left]:text-left
    [&_.new-layout_.text-right]:text-right

    [&_.new-layout_hr.green-border]:border-t-2
    [&_.new-layout_hr.green-border]:border-solid
    [&_.new-layout_hr.green-border]:border-t-green-500
    [&_.new-layout_hr]:border-t
    [&_.new-layout_hr]:border-solid
    [&_.new-layout_hr]:border-t-green-100

    [&_.new-layout_img.large-responsive]:w-full
    [&_.new-layout_img.large-responsive]:md:w-[70%]
    [&_.new-layout_img.large]:w-full
    [&_.new-layout_img.large]:md:w-[70%]
    [&_.new-layout_img.medium-responsive]:w-full
    [&_.new-layout_img.medium-responsive]:md:w-3/5
    [&_.new-layout_img.medium]:w-[90%]
    [&_.new-layout_img.medium]:md:w-3/5
    [&_.new-layout_img.small-responsive]:w-full
    [&_.new-layout_img.small-responsive]:md:w-1/2
    [&_.new-layout_img.small]:w-4/5
    [&_.new-layout_img.small]:md:w-1/2

    [&_.wrapper]:w-full
    [&_.wrapper]:bg-gray-100
    [&_.wrapper]:px-3

    [&_.youtube-iframe-container]:flex
    [&_.youtube-iframe-container]:justify-center

    [&_a]:text-primary-600
    [&_a]:transition-colors
    [&_a]:duration-100
    hover:[&_a]:text-primary-800

    [&_blockquote]:my-4
    [&_blockquote]:pl-10

    [&_button]:inline-flex
    [&_button]:select-none
    [&_button]:appearance-none
    [&_button]:items-center
    [&_button]:justify-center
    [&_button]:gap-1.5
    [&_button]:space-x-2
    [&_button]:rounded-md
    [&_button]:border-2
    [&_button]:border-transparent
    [&_button]:bg-primary-700
    [&_button]:px-4
    [&_button]:py-1.5
    [&_button]:font-semibold
    [&_button]:text-white
    [&_button]:no-underline
    [&_button]:outline-none
    [&_button]:transition
    [&_button]:duration-100
    [&_button]:ease-in
    hover:[&_button]:bg-primary-800
    focus:[&_button]:ring-2
    focus:[&_button]:ring-primary-300
    active:[&_button]:bg-primary-900
    disabled:[&_button]:cursor-not-allowed
    disabled:[&_button]:border-neutral-100
    disabled:[&_button]:bg-neutral-100
    disabled:[&_button]:text-neutral-300
    disabled:[&_button]:shadow-none
    disabled:hover:[&_button]:bg-neutral-100

    [&_em]:italic

    [&_h1]:mb-2
    [&_h1]:mt-6
    [&_h1]:font-bold
    [&_h1]:heading-xl

    [&_h2]:mb-2
    [&_h2]:mt-6
    [&_h2]:font-bold
    [&_h2]:heading-lg

    [&_h3]:mb-2
    [&_h3]:mt-6
    [&_h3]:text-xl
    [&_h3]:font-bold
    [&_h3]:md:heading-md

    [&_h4]:font-semibold

    [&_iframe]:!inline
    [&_iframe]:max-h-[225px]
    [&_iframe]:!w-full
    [&_iframe]:px-2
    [&_iframe]:sm:max-h-[300px]
    [&_iframe]:sm:max-w-[450px]
    [&_iframe]:md:max-h-[315px]
    [&_iframe]:md:max-w-[560px]
    [&_iframe]:md:p-2

    [&_img]:mx-auto
    [&_img]:w-full
    [&_img]:max-w-full
    [&_img]:md:w-1/2

    [&_ol]:my-4
    [&_ol]:px-10

    [&_p]:my-3
    [&_p]:text-lg
    [&_p_img.left]:md:float-left
    [&_p_img.left]:md:mr-4
    [&_p_img.left]:md:w-[45%]
    [&_p_img.medium]:md:mx-auto
    [&_p_img.medium]:md:block
    [&_p_img.medium]:md:max-w-full
    [&_p_img.right]:md:float-right
    [&_p_img.right]:md:ml-4
    [&_p_img.small]:md:w-1/2
    [&_p_img]:w-full

    [&_strong]:font-bold

    [&_u]:underline

    [&_ul]:list-disc
    [&_ul]:pl-4
    [&_ul]:text-lg

    [&_ul_li]:mb-2
  `,
});
