'use client';

import { memo } from 'react';

import { AlertDialog, Button } from '@backyard-ui/core';
import { isBrowser } from '@backyard-ui/utils';

import { useGlobalStore } from '@/presenters/store/global';

import { type Trigger } from '../../model';

interface LoggedOutAlertProps {
  ariaLabel: string;
  trigger: Trigger;
  onClick?: () => void;
}

function LoggedOutAlert(props: Readonly<LoggedOutAlertProps>) {
  const { trigger: TriggerButton, ariaLabel, onClick } = props;

  const hasUserResources = useGlobalStore((state) =>
    Boolean(state.user.resources)
  );

  return (
    <AlertDialog.Root size="md">
      <AlertDialog.Trigger asChild>
        <TriggerButton
          aria-label={ariaLabel}
          isInWishlist={false}
          isDisabled={!hasUserResources}
          onClick={onClick}
        />
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Title>Adicionar à lista de favoritos</AlertDialog.Title>

        <AlertDialog.Description>
          Para utilizar as listas de favoritos é necessário efetuar login,
          deseja realizar esta operação?
        </AlertDialog.Description>
        <AlertDialog.Footer>
          <AlertDialog.Cancel asChild>
            <Button appearance="neutral">Não</Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button asChild>
              <a
                href={
                  isBrowser
                    ? `/login?redirect=${window.location.href}`
                    : '/login'
                }
              >
                Sim
              </a>
            </Button>
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
}

export default memo(LoggedOutAlert);
