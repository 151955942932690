'use client';

import { useGlobalStore } from '@/presenters/store/global';

import {
  LoggedOutAlert,
  RemoveFromWishlistButton,
  WishlistDrawer,
} from './components';
import { useWishlistItem } from './hooks';
import { type Trigger, type Item } from './model';

const ARIA_LABELS = {
  add: 'adicionar à lista de favoritos',
  remove: 'remover da lista de favoritos',
};

interface WishlistProps {
  item: Item;
  trigger: Trigger;
  onAdd?: (listName: string) => void;
  onClick?: () => void;
  onCreate?: (listName: string) => void;
  onRemove?: () => void;
}

function Wishlist(props: Readonly<WishlistProps>) {
  const { item, trigger, onAdd, onClick, onCreate, onRemove } = props;

  const isAuthenticated = useGlobalStore(
    (state) => state.user.controls?.isLoggedIn
  );

  const { isInWishlist, isLoading: isLoadingItemsInWishlist } =
    useWishlistItem(item);

  if (!isAuthenticated) {
    return (
      <LoggedOutAlert
        trigger={trigger}
        ariaLabel={ARIA_LABELS.add}
        onClick={onClick}
      />
    );
  }

  if (isInWishlist) {
    return (
      <RemoveFromWishlistButton
        item={item}
        trigger={trigger}
        isDisabled={isLoadingItemsInWishlist}
        ariaLabel={ARIA_LABELS.remove}
        onClick={onClick}
        onRemove={onRemove}
      />
    );
  }

  return (
    <WishlistDrawer
      item={item}
      trigger={trigger}
      isDisabled={isLoadingItemsInWishlist}
      ariaLabel={ARIA_LABELS.add}
      onAdd={onAdd}
      onClick={onClick}
      onCreate={onCreate}
    />
  );
}

export default Wishlist;
